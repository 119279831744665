<template>
  <div class="wrap">
    <header class="cursorP">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterprise/netJob' }">当前位置：{{name}}</el-breadcrumb-item>
        <el-breadcrumb-item>已参会职位</el-breadcrumb-item>
      </el-breadcrumb>
    </header>

    <div
      class="joinList"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <div class="flex marB10 lines">
        <div class="now">
          当前职位共
          <span>{{total}}</span> 个
        </div>
        <el-button class="add" @click="open()">+新增职位</el-button>
      </div>
      <div class="job_li" v-for="item in fairJobList" :key="item.id">
        <div class="flex top_1">
          <div class="li_one">
            <div class="flex line_h4">
              <h4 class="line_clamp1">
                <router-link class="line_clamp1"
                  :to="'/enterprise/postDetails?id='+item.jobId"
                  target="_blank"
                >{{item.jobName}}</router-link>
              </h4>
              <span v-if="item.salary">{{item.salary}}</span>
              <span v-else>薪资面议</span>
            </div>

            <ul class="one_ul flex">
              <li>
                <span v-if="item.exp ">{{ item.exp }}</span>
                <span v-else>无经验要求</span>
                <el-divider direction="vertical"></el-divider>
              </li>
              <li>
                <span v-if="item.edu">{{ item.edu }}</span>
                <span v-else>无学历要求</span>
                <el-divider direction="vertical"></el-divider>
              </li>
              <li v-for="(n,i) in item.jobDisableType" :key="i">
                <span v-if="i<1">{{n.disType}}~{{n.disTypeLevel}}</span>
              </li>
              <li v-if="item.jobDisableType.length<=0">
                <span>暂无残疾类别</span>
              </li>
            </ul>
            <ul class="one_ul2 line_clamp1">
              <li v-for="(n,i) in item.jobLabels" :key="i">
                <p>{{n.labelName}}</p>
              </li>
            </ul>
          </div>
          <ul class="li_two flex">
            <li>
              <p class="nums">{{item.applyNum}}</p>
              <p class="dos">已投递</p>
            </li>
            <li>
              <p class="nums">{{item.agree}}</p>
              <p class="dos">应聘简历</p>
            </li>
            <li>
              <p class="nums">{{item.ms}}</p>
              <p class="dos">面试</p>
            </li>
          </ul>
          <div class="li_three flexs">
            <el-button @click="cancle(item.jobId)">取消参会</el-button>
          </div>
        </div>
        <p class="times">参会时间：2020.12.09</p>
      </div>
      <status v-if="fairJobList==null||fairJobList.length<=0" type="post" des="暂无已参会职位"></status>

      <div class="pagination flexs marT30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          class="work_pagination"
          :current-page.sync="params.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total,prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="请选择职位"
      :visible.sync="isShow"
      width="600px"
      height="100%"
      :before-close="handleClose"
      v-if="isShow"
    >
      <div class="flex">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          class="marB10"
        >全选</el-checkbox>
        <el-button type="primary" class="all cursorP" @click.stop="joinJobFair">立即参会</el-button>
      </div>
      <div class="marB10"></div>
      <el-checkbox-group
        v-model="checkedList"
        @change="handlecheckedListChange"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <div class="job_li" v-for="item in fairList" :key="item.id">
          <el-checkbox :label="item">
            <div class="flex top_1">
              <div class="li_one">
                <div class="flex">
                  <h4 class="line_clamp1">{{item.jobName}}</h4>
                  <span>{{item.jobSalary}}</span>
                </div>

                <ul class="one_ul flex">
                  <li>
                    <span v-if="item.exp ">{{ item.exp }}</span>
                    <span v-else>无经验要求</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    <span v-if="item.edu">{{ item.edu }}</span>
                    <span v-else>无学历要求</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li v-for="(n,i) in item.distype" :key="i">
                    <span v-if="i<1">{{n.disType}}~{{n.disTypeLevel}}</span>
                  </li>
                  <li v-if="item.distype.length<=0">
                    <span>暂无残疾类别</span>
                  </li>
                </ul>
                <ul class="one_ul2 line_clamp1">
                  <li v-for="(n,i) in item.jobLabels" :key="i">
                    <p>{{n.labelName}}</p>
                  </li>
                  <li v-if="item.jobLabels.length<=0">
                    <span>暂无福利</span>
                  </li>
                </ul>
              </div>
            </div>
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <status v-if="fairList==null||fairList.length<=0" type="post" des="暂无可参会职位"></status>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      loading: false,
      total: 0,
      checkedList: [],
      checkAll: false,
      isIndeterminate: true,
      name: "",
      params: {
        city: "",
        isJoin: "",
        jobFairId: "",
        pageNum: 1,
        pageSize: 10,
        jobStatus: 1,
      },
      paran: {
        jobFairId: "",
        jobId: [],
      },
      result: "暂无可参会职位",
      fairList: [],
      fairJobList: [],
    };
  },
  methods: {
    handleClose() {
      this.isShow = false;
    },
    open() {
      this.isShow = true;
      this.getPostlist();
    },
    cancle(id) {
      let para = {
        jobFairId: this.$route.query.id,
        jobId: id,
      };
      this.$confirm("确定要取消该职位的参会资格?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$api.cancelJoinJobFair(para);
          if (res.data.success) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.jobfairJobList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 弹窗列表
    async getPostlist() {
      this.params.jobFairId = this.$route.query.id;
      let that = this;
      this.loading = true;
      let res = await that.$axios.post(
        "/api/app-jycy-job/getJobListByComCenter",
        that.params
      );
      if (res.data.success) {
        console.log(res.data.data.records);
        this.fairList = res.data.data.records;

        this.loading = false;
      }
    },
    async jobfairJobList(id, name, ingType) {
      let pas = {
        jobFairId: this.$route.query.id,
        pageNum: 1,
        pageSize: 10,
      };
      this.loading = true;
      try {
        let res = await this.$api.jobfairJobList(pas);
        console.log(res);
        if (res.data.success) {
          this.fairJobList = res.data.data.records;
          this.total = res.data.data.total;
          document.title = name || '参会职位';
          this.name = name;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {}
    },

    async joinJobFair(id, ids) {
      this.paran.jobFairId = this.$route.query.id;
      let idList = [];
      this.checkedList.forEach((rs) => {
        console.log(rs.id, "dfds");
        idList.push(rs.id);
      });
      this.paran.jobId = idList.join(",");

      try {
        let res = await this.$api.joinJobFair(this.paran);
        console.log(res);
        if (res.data.success) {
          this.$message.success("参会成功");
          this.jobfairJobList();
        } else {
          this.$message.error(res.data.msg);
        }
        this.handleClose();
      } catch (error) {}
    },
    selectList() {
      this.$message.success("选中职位已成功参选！");
      this.isShow = false;
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.fairList : [];
      this.isIndeterminate = false;
    },
    handlecheckedListChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fairList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.fairList.length;
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
  },
  created() {
    this.jobfairJobList(
      this.$route.query.id,
      this.$route.query.name,
      this.$route.query.ingType
    );
    this.$emit('goRouter', '/enterprise/netJob');
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/public";
/deep/ .el-breadcrumb__item .el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}
/deep/ .el-dialog {
  // height: 700px;
  overflow: auto;
  // position: relative;
  // .el-dialog__header{
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  // }
}
/deep/ .el-dialog__body {
  padding-top: 5px;
  padding-bottom: 100px;
}
.wrap {
  width: 1200px;
  padding: 20px 30px;
  margin: auto;
  header {
    margin-bottom: 15px;
    .el-breadcrumb {
      color: #666666;
      font-weight: normal;
      font-size: 16px;
    }
  }
  .lines {
    border-bottom: 2px solid #dfdfdf;
    padding-bottom: 10px;
    .now {
      flex: 1;
      span {
        color: #00924c;
      }
    }
  }

  .el-button {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 32px;
    background: rgba(0, 146, 75, 0.2);
    color: #00924c;
    border: none;
    font-size: 14px;
  }
  .joinList {
    padding: 20px 30px;
    background: #ffffff;
  }
  .job_li {
    border: 1px solid #f1f1f1;
    border-bottom: none;
    width: 100%;
    box-sizing: border-box;
    height: 146px;
    margin-bottom: 15px;
    .top_1 {
      padding: 15px 20px 0px 40px;
      margin-bottom: 10px;
    }
    .li_one {
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 263px;
      .line_h4 {
        a {
          color: #00924c;
        }
      }
      h4 {
        font-size: 16px;
        color: #00924c;
        width: 80px;
        display: block;
      }
      span {
        color: #f65150;
        margin-left: 30px;
      }
      .one_ul {
        margin: 10px 0px;
        li {
          font-size: 12px;
          color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            color: #666666;
            margin-left: 0px;
          }
          .el-divider {
            margin: 0px 10px;
          }
        }
      }
      .one_ul2 {
        width: 200px;
        li {
          display: inline-block;
          border: 1px solid #ebebeb;
          font-size: 12px;
          color: #666666;
          margin-right: 12px;
          padding: 0px 3px;
        }
      }
    }
    .li_two {
      align-items: center;
      justify-content: space-around;
      li {
        width: 173px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px dotted #e1e1e1;
        .nums {
          font-size: 24px;
          color: #00924c;
          margin-bottom: 10px;
        }
        .dos {
          font-size: 12px;
          color: #666666;
        }
      }
      li:last-child {
        border: none;
      }
    }
    .li_three {
      margin-left: 50px;
    }
  }
  .times {
    width: 100%;
    height: 30px;
    padding: 0px;
    padding-left: 40px;
    background: #fafafa;
    font-size: 12px;
    margin: 0;
    line-height: 30px;
    color: #666666;
  }
  .el-dialog {
    
    .job_li {
      height: 130px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      .el-checkbox {
        margin-left: 30px;
      }
    }
    .top_1 {
      padding: 20px;
    }
  .all {
    color: #fff;
    margin-left: 20px;
    transform: translateY(-5px);
    background: @00;
    position: absolute;
    bottom: 26px;
    right: 50px;
  }
  }
}
</style>